<template>
  <b-card
    class="box-shadow-0"
    no-body
  >
    <b-card-body class="pb-0">
      <b-row class="mb-1">
        <b-col md="6">
          <b-form-group
            label="Day Title"
            label-for="day-title"
          >
            <b-input-group>
              <b-form-input
                id="day-title"
                v-model="dayData.title"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Day Index"
            label-for="day-index"
          >
            <b-input-group>
              <b-form-input
                id="day-index"
                v-model="dayData.t_index"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end">
        <b-button
          variant="outline-primary"
          class="mr-2"
          @click="$emit('close')"
        >
          Close
        </b-button>
        <b-button
          variant="primary"
          :disabled="isBtnLoading"
          @click="getDayData()"
        >
          <b-spinner
            v-if="isBtnLoading"
            small
          />
          {{ day.id ? 'Update':'Submit' }}
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'DayForm',
  mixins: [helper],
  props: {
    day: {
      type: Object,
    },
  },
  data() {
    return {
      dayData: {
        t_index: '',
        title: '',
        tour: '',
      },
      isBtnLoading: false,
    }
  },
  mounted() {
    this.dayData = this.day
  },
  methods: {
    getDayData() {
      const formData = {
        t_index: this.dayData.t_index,
        title: this.dayData.title,
        tour: this.dayData.tour,
      }
      const { id } = this.day
      let url = 'tour/admin_services/day_intent_create/'
      let apiResponse
      if (id) {
        url = `tour/admin_services/day_intent_update/${id}/`
        apiResponse = api.updateData(url, true, formData)
      } else {
        apiResponse = api.postData(url, true, formData)
      }
      this.isBtnLoading = true
      this.updateDay(apiResponse)
    },
    updateDay(apiResponse) {
      apiResponse.then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', `Itinerary day ${this.day.id ? 'updated' : 'created'} successfully`)
          this.getTour()
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
    getTour() {
      this.$emit('close')
      this.$emit('getTour')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
