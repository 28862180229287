<template>
  <b-card class="box-shadow-1">
    <DayForm
      v-if="isDayForm"
      :day="dayData"
      @getTour="getTour()"
      @close="closeDayForm"
    />
    <IntentForm
      v-if="isIntentForm"
      :id="intentId"
      :intent="intent"
      @getTour="getTour()"
      @close="closeDayForm"
    />
    <ItineraryList
      v-if="isItineraryList"
      :itinerary-data="tourData.tour_day_intent"
      :tour-id="tourId"
      @addDay="addDay"
      @addIntent="addIntent"
      @editDayForm="editDayForm"
      @editIntent="editIntentForm"
    />
  </b-card>
</template>

<script>
import DayForm from './itinerary/DayForm.vue'
import IntentForm from './itinerary/IntentForm.vue'
import ItineraryList from './itinerary/ItineraryList.vue'
import tour from '@/mixins/tour'
import helper from '@/mixins/helper'

export default {
  name: 'Itinerary',
  components: {
    DayForm,
    IntentForm,
    ItineraryList,
  },
  mixins: [tour, helper],
  data() {
    return {
      tourData: {},
      tourId: '',
      intentId: null,
      dayData: {},
      intent: {},
      isTourDataLoaded: false,
      isDayForm: false,
      isIntentForm: false,
      isItineraryList: true,
    }
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.tourId = this.$route.params.id
      this.getTour()
    } else {
      this.isTourDataLoaded = true
    }
  },
  methods: {
    getTour() {
      this.getTourData(this.tourId).then(response => {
        if (response.data.status) {
          this.tourData = response.data.result
          this.isTourDataLoaded = true
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          if (response.data.error.code === 400) {
            this.$router.push('/')
          }
        }
      }).catch()
    },
    editDayForm(data) {
      this.dayData = data
      this.isDayForm = true
      this.isItineraryList = false
    },
    editIntentForm(intent) {
      this.intent = intent
      this.isIntentForm = true
      this.isItineraryList = false
    },
    addIntent(intentId) {
      this.intentId = intentId
      this.isIntentForm = true
      this.isItineraryList = false
    },
    addDay(newDay) {
      this.dayData = newDay
      this.isDayForm = true
      this.isItineraryList = false
    },
    closeDayForm() {
      this.isDayForm = false
      this.isIntentForm = false
      this.isItineraryList = true
      this.dayData = {}
      this.intent = {}
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
