<template>
  <b-card
    class="box-shadow-0 itinerary-list"
  >
    <b-card-title
      v-if="itineraryData"
      class="d-flex justify-content-between align-items-center"
    >
      Itinerary List
      <b-button
        variant="outline-primary"
        @click="addDay()"
      >
        <feather-icon
          icon="PlusIcon"
          size="16"
          class="align-middle text-body edit"
        />
        Add Day
      </b-button>
    </b-card-title>
    <b-card-body class="pb-0">
      <b-row
        v-for="(itinerary,i) in itineraryData"
        :key="i"
        class="mb-1"
      >
        <div class="section-header">
          <div class="d-flex align-items-center">
            <h4 class="mb-0">
              {{ itinerary.title }}
            </h4>
            <b-badge
              pill
              variant="light-primary badge-btn"
              @click="editDay(itinerary)"
            >
              <feather-icon
                icon="Edit2Icon"
                size="16"
                class="align-middle text-body edit"
              />
            </b-badge>
          </div>
          <div class="section-btn">
            <b-button
              variant="outline-primary"
              @click="addIntent(itinerary.id)"
            >
              Add Intent
            </b-button>
          </div>
        </div>
        <b-table
          id="reportTable"
          :items="itinerary.tour_intent"
          primary-key="id"
          responsive
          show-empty
          thead-class="d-none"
          :fields="tableColumns"
          class="position-relative"
        >
          <template #cell(actions)="data">
            <b-badge
              pill
              variant="light-primary badge-btn"
              @click="editIntent(data.item, itinerary.id)"
            >
              <feather-icon
                icon="Edit2Icon"
                size="16"
              />
            </b-badge>
          </template>
        </b-table>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'ItineraryList',
  props: {
    itineraryData: {
      type: Array,
    },
    tourId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      intentId: '',
      tableColumns: [
        {
          key: 'title', label: '', thClass: 'md',
        },
        {
          key: 'category.name', label: '', thClass: 'md', formatter: value => value || 'N/A',
        },
        { key: 'actions', label: '' },
      ],
    }
  },
  methods: {
    editIntent(data, dayId) {
      const intent = data
      intent.day_intent = dayId
      this.$emit('editIntent', intent)
    },
    editDay(dayIntent) {
      this.$emit('editDayForm', dayIntent)
    },
    addIntent(intentId) {
      this.$emit('addIntent', intentId)
    },
    addDay() {
      const numOfDay = this.itineraryData.length + 1
      const data = {
        t_index: numOfDay,
        title: `Day ${numOfDay}`,
        tour: this.tourId,
      }
      this.$emit('addDay', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.itinerary-list {
  .section-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      margin-right: 5px;
    }
  }
  .badge-btn {
    cursor: pointer;
  }
}
</style>
